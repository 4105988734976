import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CustomerSuccessStoriesIndexStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import DynamicComponent from "../general/DynamicComponent"
import { isUndefined } from "../../utils/typeGuards"
import CustomerSuccessStoriesGrid, { SuccessStory, parseUseCase } from "../general/CustomerSuccessStoriesGrid"
import useRegion from "../../hooks/useRegion"
import useLocalizedCSStories from "../../hooks/useLocalizedCSStories"
import SearchWithTags from "../general/SearchWithTags"
import useLocale from "../../hooks/useLocale"

interface Props {
  readonly blok: CustomerSuccessStoriesIndexStoryblok
}

const moveLocalStoriesToTop = (stories: SuccessStory[], region: string) => {
  const sortedStories = stories.reduce((acc, story) => {
    if (story.content.region === region) return [[...acc[0], story], acc[1]]
    return [acc[0], [...acc[1], story]]
  }, [[], []])

  return sortedStories.flat()
}

const hasUseCase = (story: SuccessStory, selectedUseCase: string) => (
  parseUseCase(story.content.use_case)?.content?.title === selectedUseCase
    || story.content.hidden_usecases?.find((useCase) => (
      useCase && parseUseCase(useCase as string | undefined)?.content?.title === selectedUseCase))
)

interface QueryResult {
  allStoryblokEntry: {
    useCases: {
      uuid: string
      content: string
      lang: string
    }[]
  }
}

const CustomerSuccessStoriesIndex: React.FC<Props> = ({ blok }) => {
  const [selectedUseCase, setSelectedUseCase] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const stories = blok.success_stories as SuccessStory[]
  const region = useRegion()
  const featuredStories = useLocalizedCSStories()

  const featuredStoriesIds = featuredStories?.map((story) => story.uuid) ?? []

  const filteredStories = stories.filter((story) => (
    (!selectedUseCase || (hasUseCase(story, selectedUseCase)))
    && !featuredStoriesIds.includes(story.uuid)
  ))

  const sortedStories = moveLocalStoriesToTop(filteredStories, region)

  const { allStoryblokEntry: { useCases } } = useStaticQuery<QueryResult>(graphql`
    query allCssUseCases{
      allStoryblokEntry(
        filter:{ field_component: { eq: "customerSuccessUseCase" }, }
      ){
        useCases: nodes{
          uuid
          lang
          content
        }
      }
    }
  `)

  // eslint-disable-next-line no-console
  console.log(useCases)

  const { locale } = useLocale()
  const localizedUseCases = useCases.filter((useCase) => useCase.lang === locale)

  if (!stories.length) return null

  return (
    <Container tw="px-0">
      <div tw="border-t-2 border-gray-6 w-full" />
      <div tw="border-t-2 border-gray-6 w-full hidden">
        <SearchWithTags
          hideSearch
          selectedTag={selectedUseCase}
          setSelectedTag={setSelectedUseCase}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          all_filter_text={blok.all_filter_title}
          search_placeholder="Search"
          tags={localizedUseCases.map((useCase) => JSON.parse(useCase.content).title)}
          noBorder
        />
      </div>
      <CustomerSuccessStoriesGrid
        stories={sortedStories}
        spacing={blok.spacing}
        width={blok.width}
        columns={4}
        showTags={selectedUseCase === null}
      >
        {!isUndefined(blok.fixed_grid_items) && blok.fixed_grid_items.length > 0 && selectedUseCase === null
            && blok.fixed_grid_items?.map((item) => <DynamicComponent key={item._uid} blok={item} />)}
      </CustomerSuccessStoriesGrid>
    </Container>
  )
}

export default CustomerSuccessStoriesIndex
